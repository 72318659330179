import gql from 'graphql-tag';

const place = gql`
  query place($id: String!) {
    place(id: $id) {
      id
      description
      formattedAddress
      latitude
      longitude
      name
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { place };
