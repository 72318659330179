import { change, Form, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import IconDelete from '../../../../components/icons/Delete';
import IconSave from '../../../../components/icons/Save';
import IconSearchLocation from '../../../../components/icons/SearchLocation';
import AsideBody from '../../../../components/Space/Aside/Body';
import {
  addressesDetails as detailsMutation,
  addressesFindplacefromtext as geocodingMutation,
} from '../../../../objects/addresses/mutations';
import placeData from '../../../../objects/places/datas.json';
import {
  placesDelete as deleteMutation,
  placesUpdate as updateMutation,
} from '../../../../objects/places/mutations';
import { place as placeQuery } from '../../../../objects/places/queries';
import PlaceType from '../../../../types/Place';
import requiredAuth from '../../../../utils/requireAuth';

const PlacePage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'place';
  // const [sessionToken] = useState(uuidv4());
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateMutationFunc] = useMutation(updateMutation);
  const [deleteMutationFunc] = useMutation(deleteMutation);
  const [detailsMutationFunc] = useMutation(detailsMutation);
  const [geocodingMutationFunc] = useMutation(geocodingMutation);
  // const [autocompleteMutationFunc] = useMutation(autocompleteMutation);
  const { data, error, loading, refetch } = useQuery(placeQuery, {
    variables: {
      id,
    },
  });

  const place: PlaceType = data && (data.place as PlaceType);

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return (
      <pre className="m-6 p-6 bg-light-400">
        {JSON.stringify(error, null, 2)}
      </pre>
    );
  }

  const handleDeleteOnClick = async (): Promise<void> => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer cette adresse ?',
    );

    if (ok) {
      const result = await deleteMutationFunc({
        variables: {
          id,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        window.history.back();
      }
    }
  };

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  // const handleAddressesAutocompleteOnClick = async () => {
  //   const result = await autocompleteMutationFunc({
  //     variables: {
  //       address: place.name || place.formattedAddress,
  //       session: sessionToken,
  //     },
  //   });
  //
  //   if (result && !result.errors) {
  //     if ('OK' === result.data.addressesAutocomplete.status) {
  //       const [
  //         {
  //           description,
  //           structured_formatting: { secondary_text: formattedAddress },
  //         },
  //       ] = result.data.addressesAutocomplete.predictions;
  //       dispatch(change(formName, 'description', description));
  //       dispatch(change(formName, 'formattedAddress', formattedAddress));
  //     }
  //   }
  // };

  const handleGeocodingOnClick = async () => {
    const result = await geocodingMutationFunc({
      variables: {
        address: `${place.name} ${place.formattedAddress}`,
      },
    });

    if (result && !result.errors) {
      console.info('handleGeocodingOnClick', result);
      if ('OK' === result.data.addressesFindplacefromtext.status) {
        const [{ place_id: placeId }] =
          result.data.addressesFindplacefromtext.candidates;

        const details = await detailsMutationFunc({
          variables: {
            placeId,
          },
        });

        if (details && !details.errors) {
          if ('OK' === details.data.addressesDetails.status) {
            const {
              result: {
                name,
                formatted_address: formattedAddress,
                geometry: {
                  location: { lat, lng },
                },
              },
            } = details.data.addressesDetails;

            if (name) {
              dispatch(change(formName, 'name', name));
            }
            if (formattedAddress) {
              dispatch(change(formName, 'formattedAddress', formattedAddress));
            }

            dispatch(change(formName, 'latitude', lat));
            dispatch(change(formName, 'longitude', lng));
          }
        }
      }
    }
  };

  const handleOnSubmit = async (values: any) => {
    await updateMutationFunc({
      variables: {
        id,
        data: {
          ...values,
        },
      },
    });

    refetch();
  };

  return (
    <GridSplit
      aside={{
        body: place && <AsideBody places={[place]} zoom={15} />,
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconLeft={IconSearchLocation}
              onClick={handleGeocodingOnClick}
              size="small"
              status="secondary"
            >
              {t(`place.edit.geocoding`)}
            </Button>
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
            />
            <Button
              iconLeft={IconDelete}
              onClick={handleDeleteOnClick}
              size="small"
              status="danger"
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: place.name,
      }}
    >
      {loading && <CircularProgress />}

      <Form
        className="mx-6 mb-6"
        datas={placeData}
        hideSubmitButton
        initialValues={place}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: t(`place.form.submit.label`),
          size: 'small',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(PlacePage, {
  admin: true,
  layout: true,
});
